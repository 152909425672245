import { useEffect, useState, useCallback } from "react";
import { Plus, XCircle } from "react-bootstrap-icons";
import { toast } from "react-toastify";

const FilterComponent = (props) => {
  const { operators, columns, setData, serviceMethod, setLoading } = props;
  const [dynamicFilters, setDynamicFilters] = useState([
    { column: "", operator: "", value: "" },
  ]);
  const [take, setTake] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [skip, setSkip] = useState("");

  const updateDynamicFilter = (index, column, operator, value) => {
    setDynamicFilters((prevFilters) => {
      const updatedFilters = [...prevFilters];
      updatedFilters[index] = { column, operator, value };
      return updatedFilters;
    });
  };

  const addFilterRow = () => {
    setDynamicFilters((prevFilters) => [
      ...prevFilters,
      { column: "", operator: "==", value: "" },
    ]);
  };

  const removeFilterRow = (index) => {
    setDynamicFilters((prevFilters) => {
      const updatedFilters = [...prevFilters];
      updatedFilters.splice(index, 1);
      return updatedFilters;
    });
  };

  const constructedParam = useCallback(() => {
    const params = {
      filters: {},
      take,
      sort_by: sortBy,
      skip,
    };

    dynamicFilters.forEach((filter) => {
      const { column, operator, value } = filter;

      if (column && (value || operator)) {
        params.filters[column] = {
          value,
          operator,
        };
      }
    });

    Object.keys(params.filters).forEach((key) => {
      if (!params.filters[key].value && !params.filters[key].operator) {
        delete params.filters[key];
      }
    });

    return params;
  }, [dynamicFilters, take, sortBy, skip]);

  useEffect(() => {
    const params = constructedParam();

    const fetchdata = async () => {
      try {
        setLoading(true);
        const response = await serviceMethod(params);
        setData(response.data.data);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        toast.error(err?.response?.data?.message);
      }
    };
    fetchdata();
  }, [constructedParam]);

  return (
    <div
      className="mx-0 py-3 px-3 mt-3"
      style={{ backgroundColor: "#e6f0ff", borderRadius: "4px" }}
    >
      {dynamicFilters.map((filter, index) => (
        <div key={index} className="row py-1">
          <div className="col-sm-4">
            <select
              className="form-select"
              value={filter.column}
              onChange={(e) =>
                updateDynamicFilter(
                  index,
                  e.target.value,
                  filter.operator,
                  filter.value
                )
              }
            >
              <option value="">Select column</option>
              {columns.map((column) => (
                <option value={column?.value}>{column?.label}</option>
              ))}
            </select>
          </div>
          <div className="col-sm-3">
            <select
              className="form-select"
              value={filter.operator}
              onChange={(e) =>
                updateDynamicFilter(
                  index,
                  filter.column,
                  e.target.value,
                  filter.value
                )
              }
            >
              <option value="">Select operator</option>
              {operators.map((operator) => (
                <option value={operator?.value}>{operator?.label}</option>
              ))}
            </select>
          </div>
          <div className="col-sm-4">
            <input
              className="form-control"
              type="text"
              value={filter.value}
              onChange={(e) =>
                updateDynamicFilter(
                  index,
                  filter.column,
                  filter.operator,
                  e.target.value
                )
              }
            />
          </div>
          <div className="col-sm-1 text-end">
            <button
              className="btn btn-danger"
              onClick={() => removeFilterRow(index)}
            >
              <i className="mx-0">
                <XCircle style={{ fontSize: "24px", marginTop: "-2px" }} />
              </i>
            </button>
          </div>
        </div>
      ))}

      <button className="btn btn-primary my-2" onClick={addFilterRow}>
        Add Filter
        <i>
          <Plus style={{ fontSize: "28px", marginTop: "-2px" }} />
        </i>
      </button>

      <div className="row">
        <div className="col-sm-3">
          <input
            className="form-control mt-2"
            placeholder="Take (number of records to fetch)"
            type="number"
            value={take}
            onChange={(e) => setTake(e.target.value)}
          />
        </div>
        <div className="col-sm-3">
          <select
            className="form-select mt-2"
            value={sortBy}
            onChange={(e) => setSortBy(e.target.value)}
          >
            <option value="">Sort By</option>
            {columns.map((column) => (
              <option value={column?.value}>{column?.label}</option>
            ))}
          </select>
        </div>
        <div className="col-sm-3">
          <input
            className="form-control mt-2"
            type="number"
            placeholder="Skip (Number of records to skip)"
            value={skip}
            onChange={(e) => setSkip(e.target.value)}
          />
        </div>
        <div className="col-sm-3">
          <input
            className="form-control mt-2"
            type="text"
            placeholder="Search"
            onChange={(e) => setSkip(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default FilterComponent;
