import { Route, Routes } from "react-router-dom";
import { lazy, Suspense } from "react";
import { ToastContainer } from 'react-toastify';
import PageLoader from "./Common/PageLoader/PageLoader";
import 'react-toastify/dist/ReactToastify.css';

import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import PersistLogin from "./Auth/Components/PersistLogin";
import RequireAuth from "./middleware/RequireAuth";
import { ReactQueryDevtools } from 'react-query/devtools';
import FailedScheduleUpdates from "./Client/Pages/Reports/FailedScheduleUpdates/FailedScheduleUpdates";

// admin pages
const AdminLayout = lazy(() => import("./Admin/Layout/AdminLayout"));
const Client = lazy(() => import("./Admin/Pages/Client/Client"));
const Company = lazy(() => import("./Admin/Pages/Company/Company"));
const CompanyProfile = lazy(() => import("./Admin/Pages/CompanyProfile/CompanyProfile"));
const AdminTerm = lazy(() => import("./Admin/Pages/Term/AdminTerm"));
const AdminAgreement = lazy(() => import("./Admin/Pages/Agreement/AdminAgreement"));
const PrivacyPolicy = lazy(() => import("./Admin/Pages/PrivacyPolicy/PrivacyPolicy"));
const SlugChangeRequest = lazy(() => import("./Admin/Pages/SlugChangeRequest/SlugChangeRequest"));

// client pages
const ClientLayout = lazy(() => import("./Client/Layout/ClientLayout"));
const ClientProfile = lazy(() => import("./Client/Pages/Profile/ClientProfile"));
const Administration = lazy(() => import("./Client/Pages/Adminstration/Administration"));
const User = lazy(() => import("./Client/Pages/Adminstration/Users/User"));
const Role = lazy(() => import("./Client/Pages/Adminstration/Roles/Role"));
const Location = lazy(() => import("./Client/Pages/Adminstration/Locations/Location"));
const AllowedIP = lazy(() => import("./Client/Pages/Adminstration/AllowedIps/AllowedIP"));
const ColorPicker = lazy(() => import("./Client/Pages/Adminstration/ColorPicker/ColorPicker"));
const Payment = lazy(() => import("./Client/Pages/Adminstration/Payment/Payment"));
const Search = lazy(() => import("./Client/Pages/Search/Search"));
const MyCompany = lazy(() => import("./Client/Pages/MyCompany/MyCompany"));
const Offer = lazy(() => import("./Client/Pages/Offers/Offer"));
const Visualization = lazy(() => import("./Client/Pages/Visualization/Visualization"));
const MyLocation = lazy(() => import("./Client/Pages/Location/MyLocation"));
const Report = lazy(() => import("./Client/Pages/Reports/Report"));
const LoginAttempts = lazy(() => import("./Client/Pages/Reports/LoginAttempts/LoginAttempts"));
const StandardReport = lazy(() => import("./Client/Pages/Reports/StandardReport/StandardReport"));
const ACHReport = lazy(() => import("./Client/Pages/Reports/StandardReport/ACHReturn/ACHReturn"));
const ExpiringCard = lazy(() => import("./Client/Pages/Reports/StandardReport/ExpiringCard/ExpiringCard"));
const RefundedPayment = lazy(() => import("./Client/Pages/Reports/StandardReport/RefundedPayment/RefundedPayment"));
const PaymentSchedule = lazy(() => import("./Client/Pages/Reports/StandardReport/PaymentSchedule/PaymentSchedule"));
const ScheduleAction = lazy(() => import("./Client/Pages/Reports/StandardReport/ScheduleAction/ScheduleAction"));

const StandardPaymentReport = lazy(() => import("./Client/Pages/Reports/StandardReport/StandardPayment/StandardPaymentReport"));
const ImportEmail = lazy(() => import("./Client/Pages/Campaigns/EmailCampaigns/ImportEmail/ImportEmail"));

const ConsumerDetailLayout = lazy(() => import("./Client/Pages/Search/ConsumerAccount/Layout/ConsumerDetailLayout"));
const AccountSummary = lazy(() => import("./Client/Pages/Search/ConsumerAccount/Pages/AccountReview/AccountSummery/AccountSummary"));
const AccountActivity = lazy(() => import("./Client/Pages/Search/ConsumerAccount/Pages/AccountReview/AccountActivity/AccountActivity"));
const Wallet = lazy(() => import("./Client/Pages/Search/ConsumerAccount/Pages/AccountReview/Wallet/Wallet"));
const Preference = lazy(() => import("./Client/Pages/Search/ConsumerAccount/Pages/AccountReview/Preference/Preference"));
const ConsumerDetailTab = lazy(() => import("./Client/Pages/Search/ConsumerAccount/Pages/AccountReview/Tabs/ConsumerDetailTab"));
const PaymentTab = lazy(() => import("./Client/Pages/Search/ConsumerAccount/Pages/Payment/Tabs/PaymentTab"));
const EmailCampaignTab = lazy(() => import("./Client/Pages/Campaigns/EmailCampaigns/Tabs/EmailCampaignTab"));
const EmailTemplate = lazy(() => import("./Client/Pages/Campaigns/EmailCampaigns/EmailTemplate/EmailTemplate"));
const EditEmailTemplate = lazy(() => import("./Client/Pages/Campaigns/EmailCampaigns/EmailTemplate/EditEmailTemplate"));
const DuplicateEmailTemplate = lazy(() => import("./Client/Pages/Campaigns/EmailCampaigns/EmailTemplate/DuplicateTemplate"));
const AddEmailTemplate = lazy(() => import("./Client/Pages/Campaigns/EmailCampaigns/EmailTemplate/AddTemplate"));
const CreateEmailCampaign = lazy(() => import("./Client/Pages/Campaigns/EmailCampaigns/EmailCampaign/CreateEmailCampaign"));
const PendingEmailCampaign = lazy(() => import("./Client/Pages/Campaigns/EmailCampaigns/PendingEmailCampaign/PendingEmailCampaign"));
const CampaignLog = lazy(() => import("./Client/Pages/Campaigns/EmailCampaigns/PendingEmailCampaign/CampaignLog"));

const Collections = lazy(() => import("./Client/Pages/Campaigns/EmailCampaigns/Collections/Collections"));
const Receipiants = lazy(() => import("./Client/Pages/Campaigns/EmailCampaigns/Collections/Receipiants"));
const EmailCampaignMetrics = lazy(() => import("./Client/Pages/Campaigns/EmailCampaigns/Metrics/EmailCampaignMetrics"));
const EmailRestriction = lazy(() => import("./Client/Pages/Campaigns/EmailCampaigns/EmailRestriction/EmailRestriction"));
const EmailReputation = lazy(() => import("./Client/Pages/Campaigns/EmailCampaigns/EmailReputation/EmailReputation"));
const Unsubscribers = lazy(() => import("./Client/Pages/Campaigns/EmailCampaigns/Unsubscribe/Unsubscribers"));
const SuppressedEmail = lazy(() => import("./Client/Pages/Campaigns/EmailCampaigns/SuppressedEmail/SuppressedEmail"));
const EmailViolation = lazy(() => import("./Client/Pages/Campaigns/EmailCampaigns/Violations/EmailViolation"));
const DNEAttempts = lazy(() => import("./Client/Pages/Campaigns/EmailCampaigns/DNEAttempts/DNEAttempts"));
const EmailLog = lazy(() => import("./Client/Pages/Campaigns/EmailCampaigns/EmailLog/EmailLog"));
const BouncedEmail = lazy(() => import("./Client/Pages/Campaigns/EmailCampaigns/BouncedEmail/BouncedEmail"));
const EmailCompliant = lazy(() => import("./Client/Pages/Campaigns/EmailCampaigns/EmailCompliant/EmailCompliant"));
const ConfigurationsTab = lazy(() => import("./Client/Pages/Campaigns/Configurations/Tabs/ConfigurationsTab"));
const ReplacableField = lazy(() => import("./Client/Pages/Campaigns/Configurations/ReplacableFields/ReplacableField"));
const SendingEmailAddress = lazy(() => import("./Client/Pages/Campaigns/Configurations/SendingEmailAddress/SendingEmailAddress"));
const StateDisclosure = lazy(() => import("./Client/Pages/Campaigns/Configurations/StateDisclosure/StateDisclosure"));
const TimeZone = lazy(() => import("./Client/Pages/Campaigns/Configurations/TimeZone/TimeZone"));

const Faqs = lazy(() => import("./Client/Pages/FAQ/Faqs"));
const CreateFaq = lazy(() => import("./Client/Pages/FAQ/CreateFaq"));
const EditFaq = lazy(() => import("./Client/Pages/FAQ/EditFaq"));
const ContactUs = lazy(() => import("./Client/Pages/ContactUs/ContactUs"));
const ReplyMessage = lazy(() => import("./Client/Pages/ContactUs/ReplyMessage"));
const EmailAccount = lazy(() => import("./Client/Pages/Adminstration/EmailAccount/EmailAccount"));
const USAePayKey = lazy(() => import("./Client/Pages/Adminstration/USAePayKey/Key"));
const ColMaxDB = lazy(() => import("./Client/Pages/Adminstration/ColMaxDB/ColMaxDB"));
const ConsumerStatus = lazy(() => import("./Client/Pages/Adminstration/ConsumerStatuses/ConsumerStatus"));
const NoAccess = lazy(() => import("./Client/Pages/NoAccessMessage/NoAccess"));
const HomeMessage = lazy(() => import("./Client/Pages/HomeMessage/HomeMessage"));
const CompanyInformation = lazy(() => import("./Client/Pages/Adminstration/CompanyInformation/CompanyInformation"));
const AboutUs = lazy(() => import("./Client/Pages/AboutUs/AboutUs"));
const EditAboutUs = lazy(() => import("./Client/Pages/AboutUs/EditAboutUs"));
const Terms = lazy(() => import("./Client/Pages/Terms/Terms"));
const Agreement = lazy(() => import("./Client/Pages/Agreement/Agreement"));

// public pages
const PageNotFound = lazy(() => import("./Common/PageNotFound"));
const Unauthorized = lazy(() => import("./Common/Unauthorized"));
const Login = lazy(() => import("./Auth/Pages/Login"));
const TwoFA = lazy(() => import("./Auth/Pages/TwoFA"));
const SuccessfulEmailVerification = lazy(() => import("./Auth/Pages/SuccessfulEmailVerification"));
const FailedEmailVerification = lazy(() => import("./Auth/Pages/FailedEmailVerification"));
const ForgotPassword = lazy(() => import("./Auth/Pages/ForgotPassword"));
const ResetLinkSent = lazy(() => import("./Auth/Pages/ResetLinkSent"));
const PasswordResetForm = lazy(() => import("./Auth/Pages/PasswordResetForm"));
const PaswordReseted = lazy(() => import("./Auth/Pages/PaswordReseted"));

const ROLES = {
    CLIENT: 1000,
    ADMIN: 3000,
};

function App() {
    return (
        <div className="App">
            <ToastContainer />
            <ReactQueryDevtools initialIsOpen={false} />
            <Suspense fallback={<PageLoader />}>
                <Routes>
                    <Route element={<PersistLogin />}>
                        <Route element={<RequireAuth allowedRoles={[ROLES.ADMIN, ROLES.CLIENT]} />}>
                            <Route path="/2fa" element={<TwoFA />} />
                            <Route path="/success-verification" element={<SuccessfulEmailVerification />} />
                            <Route path="/failed-verification" element={<FailedEmailVerification />} />
                        </Route>
                    
                        {/* admin routes */}
                        <Route element={<RequireAuth allowedRoles={[ROLES.ADMIN]} />}>
                            <Route path="/admin" element={<AdminLayout />}>
                                <Route index element={<Company />} />
                                <Route path="company/:companyId/clients" element={<Client />} />
                                <Route path="company/:companyId" element={<CompanyProfile />} />
                                <Route path="terms" element={<AdminTerm />} />
                                <Route path="agreement" element={<AdminAgreement />} />
                                <Route path="slug-change-requests" element={<SlugChangeRequest />} />
                                <Route path="privacy-policy" element={<PrivacyPolicy />} />
                            </Route>
                        </Route>

                        {/* client routes */}
                        <Route element={<RequireAuth allowedRoles={[ROLES.CLIENT]} />}>
                            <Route path="/client" element={<ClientLayout />}>
                                <Route path="" element={<ClientProfile />} />
                                <Route path="profile" element={<ClientProfile />} />
                                <Route path="search" element={<Search />} />
                                <Route path="reports" element={<Report />}>
                                    <Route path="login-attempts" element={<LoginAttempts />} />
                                    <Route path="failed-schedule-updates" element={<FailedScheduleUpdates />} />
                                    <Route path="" element={<StandardReport />} />
                                    <Route path="standard-report" element={<StandardReport />} />
                                    <Route path="ach-report" element={<ACHReport />} />
                                    <Route path="expiring-cards" element={<ExpiringCard />} />
                                    <Route path="refunded-payments" element={<RefundedPayment />} />
                                    <Route path="payment-schedule" element={<PaymentSchedule />} />
                                    <Route path="schedule-actions" element={<ScheduleAction />} />
                                    <Route path="standard-payment-report" element={<StandardPaymentReport />} />
                                </Route>
                                <Route path="setings" element={<Administration />}>
                                    <Route path="" element={<User />} />
                                    <Route path="users" element={<User />} />
                                    <Route path="roles" element={<Role />} />
                                    <Route path="locations" element={<Location />} />
                                    <Route path="allowed-ips" element={<AllowedIP />} />
                                    <Route path="email-accounts" element={<EmailAccount />} />
                                    <Route path="usaepay-keys" element={<USAePayKey />} />
                                    <Route path="col-max-db" element={<ColMaxDB />} />
                                    <Route path="consumer-statuses" element={<ConsumerStatus />} />
                                    <Route path="company-information" element={<CompanyInformation />} />
                                    <Route path="color-picker" element={<ColorPicker />} />
                                    <Route path="payments" element={<Payment />} />
                                </Route>
                                <Route path="campaigns">
                                    <Route path="email" element={<EmailCampaignTab />}>
                                        <Route path="" element={<EmailTemplate />} />
                                        <Route path="templates" >
                                            <Route index element={<EmailTemplate />} />
                                            <Route path="edit-email-template/:templateId" element={<EditEmailTemplate />} />
                                            <Route path="add-email-template" element={<AddEmailTemplate />} />
                                            <Route path="duplicate/:templateId" element={<DuplicateEmailTemplate />} />
                                        </Route>
                                        <Route path="send-email-campaign" element={<CreateEmailCampaign />} />
                                        <Route path="pending-email-campaign">
                                            <Route index element={<PendingEmailCampaign />} />
                                            <Route path="campaign-log/:campaignId" element={<CampaignLog />} />
                                        </Route>
                                        <Route path="collections">
                                            <Route index element={<Collections />} />
                                            <Route path="receipiants/:collectionId" element={<Receipiants />} />
                                        </Route>
                                        <Route path="import-emails" element={<ImportEmail />} />
                                        <Route path="email-campaign-metrics" element={<EmailCampaignMetrics />} />
                                        <Route path="email-restrictions" element={<EmailRestriction />} />
                                        <Route path="email-reputations" element={<EmailReputation />} />
                                        <Route path="unsubscribers" element={<Unsubscribers />} />
                                        <Route path="suppressed-emails" element={<SuppressedEmail />} />
                                        <Route path="email-violation" element={<EmailViolation />} />
                                        <Route path="DNE-attempts" element={<DNEAttempts />} />
                                        <Route path="email-logs" element={<EmailLog />} />
                                        <Route path="bounced-emails" element={<BouncedEmail />} />
                                        <Route path="email-compliant" element={<EmailCompliant />} />
                                    </Route>
                                    <Route path="configration" element={<ConfigurationsTab />}>
                                        <Route index element={<ReplacableField />} />
                                        <Route path="replaceable-fields" element={<ReplacableField />} />
                                        <Route path="sending-email-address" element={<SendingEmailAddress />} />
                                        <Route path="state-disclosure" element={<StateDisclosure />} />
                                        <Route path="time-zone" element={<TimeZone />} />
                                    </Route>
                                    <Route path="sms">

                                    </Route>
                                </Route>
                                <Route path="my-location" element={<MyLocation />}></Route>
                                <Route path="my-company" element={<MyCompany />} />
                                <Route path="offers" element={<Offer />} />
                                <Route path="visualization" element={<Visualization />} />
                                <Route path="faqs">
                                    <Route index element={<Faqs />} />
                                    <Route path="create-faq" element={<CreateFaq />} />
                                    <Route path="edit-faq/:faqId" element={<EditFaq />} />
                                </Route>
                                <Route path="contact-us">
                                    <Route index element={<ContactUs />} />
                                    <Route
                                        path="reply-message/:messageId"
                                        element={<ReplyMessage />}
                                    />
                                </Route>
                                <Route path="about-us" element={<AboutUs />} />
                                <Route path="edit-about-us" element={<EditAboutUs />} />
                                <Route path="terms" element={<Terms />} />
                                <Route path="agreements" element={<Agreement />} />
                                <Route path="company-home-message" element={<HomeMessage />} />
                                <Route path="no-access-message" element={<NoAccess />} />
                            </Route>

                            <Route path="/client-consumer" element={<ConsumerDetailLayout />}>
                                <Route path="" element={<ConsumerDetailTab />}>
                                    <Route path="" element={<AccountSummary />} />
                                    <Route path="account-summery" element={<AccountSummary />} />
                                    <Route
                                        path="account-activity"
                                        element={<AccountActivity />}
                                    />
                                    <Route path="consumer-wallet" element={<Wallet />} />
                                    <Route path="consumer-preference" element={<Preference />} />
                                </Route>
                                <Route path="payment" element={<PaymentTab />} />
                            </Route>
                        </Route>
                    </Route>

                    <Route path="*" element={<PageNotFound />} />
                    <Route path="/" element={<Login />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/forgot-password" element={<ForgotPassword />} />
                    <Route path="/reset-link-sent" element={<ResetLinkSent />} />
                    <Route path="/reset-password-form" element={<PasswordResetForm />} />
                    <Route path="/password-reseted" element={<PaswordReseted />} />
                    <Route path="/unauthorized" element={<Unauthorized />} />
                </Routes>
            </Suspense>
        </div>
    );
}

export default App;
