// urls.js
export const VIEW_CONSUMER_URL = "/client-consumer/account-summery";
export const ROOT_URL = "/";
export const ADMIN_URL = "/admin";
export const CLIENT_URL = "/client";
export const RESET_LINK_SENT_URL = "/reset-link-sent";
export const PASSWORD_RESETED = "/password-reseted";
export const SLUG_CHANGE_REQUESTS = "slug-change-requests";
export const TERMS = "terms";
export const AGREEMENT = "agreement";
export const FORGOT_PASSWORD = "/forgot-password";
export const LOGIN_URL = "/login";
export const TwoFA_URL = "/2fa";
export const PROFILE_URL = "profile";
export const SEARCH_URL = "search";
export const REPORTS_URL = "reports";
export const SETTINGS_URL = "setings";
export const VISUALIZATION_URL = "visualization";
export const MY_COMPANY_URL = "my-company";
export const OFFERS_URL = "offers";
export const MY_LOCATION_URL = "my-location";
export const FAQS_URL = "faqs";
export const CONTACT_US_URL = "contact-us";
export const ABOUT_US_URL = "about-us";
export const EDIT_ABOUT_US_URL = "/client/edit-about-us";
export const USERS_URL = "users";
export const LOCATIONS_URL = "locations";
export const ROLES_URL = "roles";
export const ALLOWED_IP_URL = "allowed-ips";
export const EMAIL_ACCOUNT_URL = "email-accounts";
export const COL_MAX_DB = "col-max-db";
export const USAEPAY_KEY_URL = "usaepay-keys";
export const PAYMENTS = "payments";
export const CONSUMER_STATUS_URL = "consumer-statuses";
export const CREATE_FAQ_URL = "create-faq";
export const LOGIN_ATTEMPT_URL = "/client/reports/login-attempts";
export const FAILED_SCHEDULE_UPDATES = "/client/reports/failed-schedule-updates";
export const STANDARD_REPORT_URL = "standard-report";
export const ACH_REPORT = "/client/reports/ach-report";
export const EXPIRING_CARDS = "/client/reports/expiring-cards";
export const REFUNDED_PAYMENTS = "/client/reports/refunded-payments";
export const SCHEDULED_PAYMENTS = "/client/reports/payment-schedule";
export const STANDARD_PAYMENT_REPORT = "/client/reports/standard-payment-report";
export const MY_REPORT_URL = "my-report";
export const ACCOUNT_SUMMERY_URL = "account-summery";
export const PAYMENT_URL = "payment";
export const CLIENT_SEARCH_URL = "/client/search";
export const ACCOUNT_ACTIVITY_URL =  "account-activity";
export const CONSUMER_WALLET_URL = "consumer-wallet";
export const CONSUMER_PREFERENCE_URL = "consumer-preference";
export const UNAUTHORIZED_URL = "/unauthorized";
export const NO_ACCESS_MESSAGE = "no-access-message";
export const AGREEMENTS = "agreements";
export const COMPANY_INFORMATION = "company-information";
export const HOME_MESSAGE = "company-home-message";
export const PRIVACY_POLICY = "privacy-policy";
export const COLOR_PICKER = "color-picker";
export const EMAIL_CAMPAIGN = "campaigns/email";
export const EMAIL_TEMPLATES = "templates";
export const EDIT_EMAIL_TEMPLATE = "/client/campaigns/email/templates/edit-email-template";
export const ADD_EMAIL_TEMPLATE = "/client/campaigns/email/templates/add-email-template";
export const DUPLICATE_EMAIL_TEMPLATE = "/client/campaigns/email/templates/duplicate";
export const SEND_EMAIL_CAMPAIGN = "send-email-campaign";
export const PENDING_EMAIL_CAMPAIGN = "pending-email-campaign";
export const CAMPAIGN_LOG = "/client/campaigns/email/pending-email-campaign/campaign-log";
export const EMAIL_CAMPAIGN_METRICS = "email-campaign-metrics";
export const COLLECTIONS = "collections";
export const COLLECTION_RECEIPIANTS = "receipiants";
export const IMPORT_EMAILS = "import-emails";
export const DNE_ATTEMPTS = "DNE-attempts";
export const EMAIL_RESTRICTIONS = "email-restrictions";
export const EMAIL_REPUTATIONS = 'email-reputations';
export const UNSUBSCRIBERS = 'unsubscribers';
export const SUPPRESSED_EMAILS = 'suppressed-emails';
export const EMAIL_VIOLATIONS = 'email-violation';
export const EMAIL_COMPLIANT = 'email-compliant';
export const EMAIL_LOGS = 'email-logs';
export const CONFIGURATIONS = 'campaigns/configration';
export const REPLACEABLE_FIELDS = 'replaceable-fields';
export const SENDING_EMAIL_ADDRESS = 'sending-email-address';
export const STATE_DISCLOSURE = 'state-disclosure';
export const TIME_ZONE = 'time-zone';
export const BOUNCED_EMAIL = 'bounced-emails';
