import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
const BASE_URL = process.env.REACT_APP_API_URL;

export const ImportReceipiantAPI = createApi({
    reducerPath: 'ImportReceipiantAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: `${BASE_URL}/company/recipients/import/`,
        credentials: 'include',
        prepareHeaders: (headers) => {
            headers.set('Accept', 'application/json')
            return headers
        },
    }),
    tagTypes: ['ImportReceipiant'],
    endpoints: (builder) => ({
        prepareFromDB: builder.mutation({
            query: (data) => ({
                url: 'prepare',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['ImportReceipiant']
        }),

        prepareFromFile: builder.mutation({
            query: (data) => ({
                url: 'prepare',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['ImportReceipiant']
        }),

        manualImport: builder.mutation({
            query: (data) => ({
                url: 'prepare',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['ImportReceipiant']
        }),

        finishImport: builder.mutation({
            query: (data) => ({
                url: 'process',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['Collections']
        }),

    }),
})

export const { usePrepareFromDBMutation,
    usePrepareFromFileMutation, 
    useManualImportMutation,
    useFinishImportMutation } = ImportReceipiantAPI