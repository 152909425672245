import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
const BASE_URL = process.env.REACT_APP_API_URL;

export const RefundedPaymentAPI = createApi({
    reducerPath: 'RefundedPaymentAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: `${BASE_URL}/company/reports`,
        credentials: 'include',
        prepareHeaders: (headers) => {
            headers.set('Accept', 'application/json')
            return headers
        },
    }),
    tagTypes: ['RefundedPayment'],
    endpoints: (builder) => ({
        getRefundedTransactions: builder.query({
            query: () => '/voided-transactions',
            providesTags: ['RefundedPayment']
        }),
    }),
})

export const { useGetRefundedTransactionsQuery } = RefundedPaymentAPI