import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
const BASE_URL = process.env.REACT_APP_API_URL;

export const EmailCampaignAPI = createApi({
    reducerPath: 'SendEmailCampaignAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: `${BASE_URL}/company/campaigns`,
        credentials: 'include',
        prepareHeaders: (headers) => {
            headers.set('Accept', 'application/json')
            return headers
        },
    }),
    tagTypes: ['EmailCampaigns'],
    endpoints: (builder) => ({
        getEmailCampaigns: builder.query({
            query: () => '/email',
            providesTags: ['EmailCampaigns']
        }),

        createEmailCampaign: builder.mutation({
            query: (data) => ({
                url: '/email',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['EmailCampaigns']
        }),

        changeCampaginStatus: builder.mutation({
            query: (data) => ({
                url: `/email/${data?.campaign_id}/change-state`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['EmailCampaigns']
        }),

        deleteEmailCampaign: builder.mutation({
            query: (id) => ({
                url: `/email/${id}`,
                method: 'delete',
            }),
            invalidatesTags: ['EmailCampaigns']
        }),

    }),
})

export const { useCreateEmailCampaignMutation, useGetEmailCampaignsQuery, 
    useDeleteEmailCampaignMutation, useChangeCampaginStatusMutation } = EmailCampaignAPI