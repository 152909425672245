import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
const BASE_URL = process.env.REACT_APP_API_URL;

export const EmailTemplateAPI = createApi({
    reducerPath: 'EmailTemplateAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: `${BASE_URL}/company/campaigns`,
        credentials: 'include',
        prepareHeaders: (headers) => {
            headers.set('Accept', 'application/json')
            return headers
        },
    }),
    tagTypes: ['EmailTemplate'],
    endpoints: (builder) => ({
        getEmailTemplates: builder.query({
            query: (word) => ({
                url: '/templates',
                params: { word }
            }),
            providesTags: ['EmailTemplate']
        }),

        showEmailTemplate: builder.query({
            query: (id) => `/templates/${id}`,
            providesTags: ['EmailTemplate']
        }),

        createEmailTemplate: builder.mutation({
            query: (data) => ({
                url: '/templates',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['EmailTemplate']
        }),

        updateEmailTemplate: builder.mutation({
            query: (data) => ({
                url: `/templates/${data.id}`,
                method: 'PATCH',
                body: data,
            }),
            invalidatesTags: ['EmailTemplate']
        }),

        deleteEmailTemplate: builder.mutation({
            query: (id) => ({
                url: `/templates/${id}`,
                method: 'delete',
            }),
            invalidatesTags: ['EmailTemplate']
        }),

    }),
})

export const { useGetEmailTemplatesQuery,
    useShowEmailTemplateQuery,
    useCreateEmailTemplateMutation,
    useUpdateEmailTemplateMutation,
    useDeleteEmailTemplateMutation } = EmailTemplateAPI