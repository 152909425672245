import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
const BASE_URL = process.env.REACT_APP_API_URL;

export const CampaignLogAPI = createApi({
    reducerPath: 'CampaignLogAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: `${BASE_URL}/company/campaigns`,
        credentials: 'include',
        prepareHeaders: (headers) => {
            headers.set('Accept', 'application/json')
            return headers
        },
    }),
    tagTypes: ['CampaignLog'],
    endpoints: (builder) => ({
        getEmailCampaignLog: builder.query({
            query: (id) => `${id}/history`,
            providesTags: ['CampaignLog']
        }),

    }),
})

export const { useGetEmailCampaignLogQuery } = CampaignLogAPI