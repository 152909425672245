import { Spinner, Table, Button } from "react-bootstrap";
import { CaretDown, CaretUp } from "react-bootstrap-icons";
import { useEffect, useState } from "react";
import useScheduleUpdateService from "./Api/failedScheduleUpdate.service";
import { toast } from "react-toastify";
import FilterComponent from "../Components/FilterComponent";

const operators = [
  { label: "Equal to", value: "=" },
  { label: "Like", value: "LIKE" },
  { label: "Greater than", value: ">=" },
  { label: "Less than", value: "<=" },
];
const columns = [
  { label: "File Number", value: "consumer.file_number" },
  { label: "SSN", value: "consumer.ssn" },
  { label: "Created At", value: "created_at" },
  { label: "Email", value: "consumer.email" },
  { label: "Phone", value: "consumer.phone" },
  { label: "Amount", value: "schedule.amount" },
  { label: "Full Name", value: "consumer.full_name" },
];

function FailedScheduleUpdates() {
  const { getFailedScheduleUpdates } = useScheduleUpdateService();

  const [isLoading, setLoading] = useState(true);

  const [failedScheduleUpdates, setFailedScheduleUpdates] = useState([]);

  const [showFilter, setShowFilter] = useState(false);

  const handleToggleFilter = () => setShowFilter(state =>!state);

  const fetchFailedScheduleUpdates = async () => {
    try {
      setLoading(true);
      const response = await getFailedScheduleUpdates();
      setFailedScheduleUpdates(response.data?.data?.items);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(err?.response?.data?.message);
    }
  };

  useEffect(() => {
    fetchFailedScheduleUpdates();
  }, []);

  return (
    <div className="mt-4">
      <div className="row">
        <div className="col-sm-6">
          <h5>Failed schedule update requests</h5>
        </div>
        <div className="col-sm-6 text-end">
          {showFilter ? (
            <div>
              <Button onClick={handleToggleFilter} className="btn btn-danger">
                Close Filters
                <i className="mx-2">
                  <CaretUp style={{ fontSize: "24px", marginTop: "-2px" }} />
                </i>
              </Button>
            </div>
          ) : (
            <Button onClick={handleToggleFilter}>
              Show Filters
              <i className="mx-2">
                <CaretDown style={{ fontSize: "24px", marginTop: "-2px" }} />
              </i>
            </Button>
          )}
        </div>
      </div>

      {showFilter && (
        <FilterComponent
          operators={operators}
          columns={columns}
          setData={setFailedScheduleUpdates}
          serviceMethod={getFailedScheduleUpdates}
          setLoading={setLoading}
        />
      )}

      <Table hover className="mt-3" size="sm">
        <thead>
          <tr>
            <th>File Number</th>
            <th>SSN</th>
            <th>Full Name</th>
            <th>Phone Number</th>
            <th>Email</th>
            <th>Amount</th>
            <th>Amount Left</th>
            <th>Frequency</th>
            <th>Created at</th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td className="text-center" colSpan={9}>
                <Spinner size="sm" animation="border" role="status">
                  <span className="visually-hidden">
                    Loading please wait...
                  </span>
                </Spinner>
              </td>
            </tr>
          ) : failedScheduleUpdates?.length ? (
            failedScheduleUpdates.map((failedScheduleUpdate) => (
              <tr key={failedScheduleUpdate.id}>
                <td>{failedScheduleUpdate?.consumer?.file_number}</td>
                <td>{failedScheduleUpdate?.consumer?.ssn}</td>
                <td>{failedScheduleUpdate?.consumer?.full_name}</td>
                <td>{failedScheduleUpdate?.consumer?.phone}</td>
                <td>{failedScheduleUpdate?.consumer?.email}</td>
                <td>${failedScheduleUpdate?.schedule?.amount}</td>
                <td>${failedScheduleUpdate?.schedule?.amount_left}</td>
                <td>{failedScheduleUpdate?.schedule?.frequency?.name}</td>
                <td>{failedScheduleUpdate.created_at}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="9" className="text-center">
                <b>No records found</b>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
}

export default FailedScheduleUpdates;
