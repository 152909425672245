import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

import { EmailTemplateAPI } from "../Client/Pages/Campaigns/EmailCampaigns/EmailTemplate/API/EmailTemplateAPI";
import { EmailCampaignAPI } from "../Client/Pages/Campaigns/EmailCampaigns/EmailCampaign/API/EmailCampaignAPI";
import { CampaignLogAPI } from "../Client/Pages/Campaigns/EmailCampaigns/PendingEmailCampaign/API/CampaignLogAPI";
import { StateDisclosureAPI } from "../Client/Pages/Campaigns/Configurations/StateDisclosure/API/StateDisclosureAPI";
import { ReplaceableFieldAPI } from "../Client/Pages/Campaigns/Configurations/ReplacableFields/API/ReplaceableFieldAPI";
import { PaymentScheduleAPI } from "../Client/Pages/Reports/StandardReport/PaymentSchedule/API/PaymentScheduleAPI";
import { CollectionAPI } from "../Client/Pages/Campaigns/EmailCampaigns/Collections/API/CollectionAPI";
import { ExpiringCardAPI } from "../Client/Pages/Reports/StandardReport/ExpiringCard/API/ExpiringCardAPI";
import { RefundedPaymentAPI } from "../Client/Pages/Reports/StandardReport/RefundedPayment/API/RefundedPaymentAPI";
import { ImportReceipiantAPI } from "../Client/Pages/Campaigns/EmailCampaigns/ImportEmail/API/ImportReceipiantAPI";
import { StandardPaymentReportAPI } from "../Client/Pages/Reports/StandardReport/StandardPayment/API/StandardPaymentReportAPI";
import { ScheduleActionAPI } from "../Client/Pages/Reports/StandardReport/ScheduleAction/API/ScheduleActionAPI";

export const Store = configureStore({
  reducer: {
    [ReplaceableFieldAPI.reducerPath]: ReplaceableFieldAPI.reducer,
    [EmailTemplateAPI.reducerPath]: EmailTemplateAPI.reducer,
    [EmailCampaignAPI.reducerPath]: EmailCampaignAPI.reducer,
    [StateDisclosureAPI.reducerPath]: StateDisclosureAPI.reducer,
    [PaymentScheduleAPI.reducerPath]: PaymentScheduleAPI.reducer,
    [CollectionAPI.reducerPath]: CollectionAPI.reducer,
    [ExpiringCardAPI.reducerPath]: ExpiringCardAPI.reducer,
    [RefundedPaymentAPI.reducerPath]: RefundedPaymentAPI.reducer,
    [ImportReceipiantAPI.reducerPath]: ImportReceipiantAPI.reducer,
    [StandardPaymentReportAPI.reducerPath]: StandardPaymentReportAPI.reducer,
    [CampaignLogAPI.reducerPath]: CampaignLogAPI.reducer,
    [ScheduleActionAPI.reducerPath]: ScheduleActionAPI.reducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      ReplaceableFieldAPI.middleware,
      EmailTemplateAPI.middleware,
      EmailCampaignAPI.middleware,
      StateDisclosureAPI.middleware,
      PaymentScheduleAPI.middleware,
      CollectionAPI.middleware,
      ExpiringCardAPI.middleware,
      RefundedPaymentAPI.middleware,
      ImportReceipiantAPI.middleware,
      StandardPaymentReportAPI.middleware,
      CampaignLogAPI.middleware,
      ScheduleActionAPI.middleware
    ),
});

setupListeners(Store.dispatch);
