import { createContext, useState } from "react";

const PaymentContext = createContext({});

export const  PaymentProvider = ({children}) => {
    const [payment, setPayment] = useState({});

    return (
        <PaymentContext.Provider value={{payment, setPayment}}>
            {children}
        </PaymentContext.Provider>
    )
}

export default PaymentContext;