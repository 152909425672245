import useAxiosPrivate from "../../../../../hooks/useAxiosPrivate";

const url = "/company/reports/failed-schedule-updates";

const useScheduleUpdateService = () => {
  const axiosPrivate = useAxiosPrivate();

  function getFailedScheduleUpdates(params) {
    return axiosPrivate.get(url, { params });
  }

  return {
    getFailedScheduleUpdates,
  };
};

export default useScheduleUpdateService;
