import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
const BASE_URL = process.env.REACT_APP_API_URL;

export const CollectionAPI = createApi({
    reducerPath: 'CollectionAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: `${BASE_URL}/company`,
        credentials: 'include',
        prepareHeaders: (headers) => {
            headers.set('Accept', 'application/json')
            return headers
        },
    }),
    tagTypes: ['Collections'],
    endpoints: (builder) => ({
        getCollections: builder.query({
            query: () => ({
                url: '/collections',
            }),
            providesTags: ['Collections']
        }),

        getCollectionRecipient: builder.query({
            query: (params) => ({
                url: `/recipients`,
                params: {collection_id: params},
            }),
            providesTags: ['Collections']
        }),

        createCollection: builder.mutation({
            query: (data) => ({
                url: '/collections',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['Collections']
        }),

        updateCollection: builder.mutation({
            query: (data) => ({
                url: `/collections/${data.id}`,
                method: 'PATCH',
                body: data,
            }),
            invalidatesTags: ['Collections']
        }),

        deleteCollection: builder.mutation({
            query: (id) => ({
                url: `/collections/${id}`,
                method: 'delete',
            }),
            invalidatesTags: ['Collections']
        }),

    }),
})

export const { useGetCollectionsQuery,
    useCreateCollectionMutation,
    useUpdateCollectionMutation,
    useGetCollectionRecipientQuery,
    useDeleteCollectionMutation } = CollectionAPI