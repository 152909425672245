import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
const BASE_URL = process.env.REACT_APP_API_URL;

export const StateDisclosureAPI = createApi({
    reducerPath: 'StateDisclosureAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: `${BASE_URL}/company/campaigns/configurations`,
        credentials: 'include',
        prepareHeaders: (headers) => {
            headers.set('Accept', 'application/json')
            return headers
        },
    }),
    tagTypes: ['StateDisclosure'],
    endpoints: (builder) => ({
        getStateDisclosures: builder.query({
            query: () => '/state-disclosures',
            providesTags: ['StateDisclosure']
        }),

        createStateDisclosure: builder.mutation({
            query: (data) => ({
                url: '/state-disclosures',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['StateDisclosure']
        }),

        updateStateDisclosures: builder.mutation({
            query: (data) => ({
                url: `/state-disclosures/${data.id}`,
                method: 'PATCH',
                body: data,
            }),
            invalidatesTags: ['StateDisclosure']
        }),

        deleteStateDisclosures: builder.mutation({
            query: (id) => ({
                url: `/state-disclosures/${id}`,
                method: 'delete',
            }),
            invalidatesTags: ['StateDisclosure']
        }),

    }),
})

export const { useGetStateDisclosuresQuery,
    useCreateStateDisclosureMutation,
    useUpdateStateDisclosuresMutation,
    useDeleteStateDisclosuresMutation
} = StateDisclosureAPI