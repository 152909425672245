import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
const BASE_URL = process.env.REACT_APP_API_URL;

export const ReplaceableFieldAPI = createApi({
    reducerPath: 'ReplaceableFieldAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: `${BASE_URL}/company/campaigns/configurations`,
        credentials: 'include',
        prepareHeaders: (headers) => {
            headers.set('Accept', 'application/json')
            return headers
        },
    }),
    tagTypes: ['ReplaceableField'],
    endpoints: (builder) => ({
        getReplaceableFields: builder.query({
            query: (word) => ({
                url: '/replaceable-fields',
                params: { word }
            }),
            providesTags: ['ReplaceableField']
        }),

        createReplaceableField: builder.mutation({
            query: (data) => ({
                url: '/replaceable-fields',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['ReplaceableField']
        }),

        updateReplaceableField: builder.mutation({
            query: (data) => ({
                url: `/replaceable-fields/${data.id}`,
                method: 'PATCH',
                body: data,
            }),
            invalidatesTags: ['ReplaceableField']
        }),

        deleteReplaceableField: builder.mutation({
            query: (id) => ({
                url: `/replaceable-fields/${id}`,
                method: 'delete',
            }),
            invalidatesTags: ['ReplaceableField']
        }),

    }),
})

export const { useGetReplaceableFieldsQuery,
    useCreateReplaceableFieldMutation,
    useUpdateReplaceableFieldMutation,
    useDeleteReplaceableFieldMutation } = ReplaceableFieldAPI