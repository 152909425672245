import { Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import useRefreshToken from '../../hooks/useRefreshToken';
import useAuth from "../../hooks/useAuth";
import PageLoader from "../../Common/PageLoader/PageLoader";

const PersistLogin = () => {
    const [isLoading, setIsLoading] = useState(true);
    const refresh = useRefreshToken();
    const { auth } = useAuth();
    useEffect(() => {
        const verifyRefreshToken = async () => {
            try {
                await refresh();
            }
            catch (err) {
                console.error(err);
            }
            finally {
                setIsLoading(false);
            }
        }
        if (!auth.access_token) {
            verifyRefreshToken()
        } else {
            setIsLoading(false);
        }
    }, [])

    useEffect(() => {
    }, [isLoading])

    return (
        <div>
            {isLoading ? <PageLoader /> : <Outlet />}
        </div>
    )
}

export default PersistLogin