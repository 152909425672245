import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { LOGIN_URL, UNAUTHORIZED_URL } from "../urls";

const RequireAuth = ({ allowedRoles }) => {
    const { auth } = useAuth();
    const location = useLocation();

    if (auth?.roles?.some(role => allowedRoles?.includes(role))) {
        return <Outlet />;
    } else if (auth?.access_token) {
        return <Navigate to={UNAUTHORIZED_URL} state={{ from: location }} replace />;
    } else {
        return <Navigate to={LOGIN_URL} state={{ from: location }} replace />;
    }
};

export default RequireAuth;
