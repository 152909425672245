import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
const BASE_URL = process.env.REACT_APP_API_URL;

export const ScheduleActionAPI = createApi({
    reducerPath: 'ScheduleActionAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: `${BASE_URL}/company/reports`,
        credentials: 'include',
        prepareHeaders: (headers) => {
            headers.set('Accept', 'application/json')
            return headers
        },
    }),
    tagTypes: ['PaymentSchedule'],
    endpoints: (builder) => ({
        getScheduleActions: builder.query({
            query: () => '/schedule-actions',
            providesTags: ['ScheduleAction']
        }),
    }),
})

export const { useGetScheduleActionsQuery } = ScheduleActionAPI